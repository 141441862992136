<template>
  <div class="container">
    <!-- Blog Posts -->
    <div class="blog-page">
      <div class="row mt-12">
        <!-- Post Content -->
        <div class="col-lg-9 col-md-8 padding-right-30">
          <!-- Blog Post -->
          <div class="blog-post single-post pt-32 px-24 py-10">
            <!-- Img -->
            <img class="post-img" src="images/valo3.jpg" alt="" />

            <!-- Content -->
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
              >
                Déterminez le profil cultural de votre terre grâce à
                l'intelligence artificielle de LandsApp.<o:p></o:p
              ></span>
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >En Afrique de l'Ouest, la main-d'œuvre dans le secteur agricole
                est un domaine d'intérêt clé pour les économies actuelles et
                futures. Les petites exploitations (≤20 ha) produisent plus de
                75 % de la plupart des denrées alimentaires et les très petites
                exploitations (≤2 ha) contribuent à environ 30 % de la plupart
                des denrées alimentaires en Afrique de l'Ouest. On peut
                s'attendre à ce que la population rurale et la main-d'œuvre dans
                l'agriculture en Afrique de l'Ouest restent massives au cours
                des prochaines décennies. Compte tenu de la taille de la
                main-d'œuvre rurale, il a été avancé que l'augmentation de
                l'agriculture dans ces régions ne devrait pas reposer sur des
                monocultures à grande échelle et l'utilisation intensive
                d'intrants, mais plutôt sur des agroécosystèmes spécifiques au
                contexte qui créent des synergies biologiques et stimulent la
                biodiversité ainsi que les fonctions écologiques pour augmenter
                et soutenir la croissance productive dans de multiples
                dimensions, offrant une multitude d'avantages à long terme
                (Dorin 2017). Certes, trouver des moyens durables d'accroître
                l'accès, la disponibilité, l'utilisation et la stabilité de la
                nourriture est essentiel à la fois pour éviter la déforestation
                et pour assurer la sécurité alimentaire en Afrique de
                l'Ouest.<o:p></o:p
              ></span>
            </p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >Les systèmes agricoles ont perdu beaucoup de leurs liens avec
                les communautés locales, car ils sont devenus - dans certaines
                régions - des monocultures orientées vers les marchés extérieurs
                par l'achat d'intrants industriels pour vendre des produits à
                des fins lucratives (FIAN 2009). De nombreux systèmes agricoles
                modernes ont cessé d'utiliser la main-d'œuvre locale et se sont
                débarrassés des avantages tirés des paysages riches en
                biodiversité, créant une perte de services environnementaux
                régionaux. Les problèmes qui en résultent sont la déforestation,
                l'érosion des sols, la perte d'espèces biologiques, la
                contamination toxique, les émissions de gaz à effet de serre et
                l'exode rural. Ainsi, il est intéressant d'identifier les
                approches agroecologiques adaptées à promouvoir afin d'alléger
                la pression que la production agro-alimentaire exerce sur les
                écosystèmes naturels.<o:p></o:p
              ></span>
            </p>
            <p></p>
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
                >Landsapp contribuera à atteindre cet objectif en fournissant un
                modèle de recommandation de pratiques et de stratégies
                culturales et agroécologiques utilisant l'apprentissage
                automatique et l'intelligence artificielle. Le modèle sera
                déployé sur une interface Web en utilisant HTML, CSS et
                Javascript<o:p></o:p
              ></span>
            </p>
          </div>
          <!-- Blog Post / End -->

          <!-- Post Navigation -->

          <!-- About Author -->
          <div class="about-author">
            <img src="images/1517394269539.jpg" alt="" />
            <div class="about-description">
              <h4>Dr. Ir. Elie Antoine Padonou, Ph.D.</h4>
              <a href="#">elieantoine.padonou@landsapp.com</a>
              <p>
                has held PhD on natural resources management since 2015. He is
                expert on land degradation & restoration, land use land cover
                change analysis, forest conservation & management, forest
                ecology, agroforestry and agronomy.
              </p>
            </div>
          </div>

          <!-- Related Posts -->

          <!-- Related Posts / End -->

          <div class="margin-top-50"></div>

          <!-- Reviews -->

          <div class="clearfix"></div>

          <!-- Add Comment -->

          <!-- Add Review Box / End -->
        </div>
        <!-- Content / End -->

        <!-- Widgets -->
        <div class="col-lg-3 col-md-4">
          <div class="sidebar right">
            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->
            <div class="widget" style="margin-top: 80px">
              <h3>Popular Posts</h3>
              <ul class="widget-tabs">
                <!-- Post #1 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails1' })"
                        ><img src="images/valorisation.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails1' })"
                          >Valoriser les terres agricoles pour mettre fin à la
                          faim
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #2 -->
                <li>
                  <div class="widget-content">
                    <div class="widget-thumb">
                      <a><img src="images/valo2.jpg" alt="" /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails2' })"
                          >Valorisez votre terre agricole au meilleur prix grâce
                          à notre mécanisme d'enchères.</a
                        >
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #3 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails3' })"
                        ><img src="images/valo3.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails3' })"
                          >Déterminez le profil cultural de votre terre grâce à
                          l'intelligence artificielle de LandsApp.
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Widget / End-->

            <!-- Widget -->

            <!-- Widget / End-->

            <div class="clearfix"></div>
            <div class="margin-bottom-40"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sidebar / End -->
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
